import { Link } from "gatsby";
import * as React from "react";
import { useState } from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import cohorte from "@images/proyectos/cohorte.png";
import smartphone from "@images/proyectos/smartphone.png";
import adorno from "@images/adorno/adorno5.png"

const IndexPage = () => {

  const [select, setselect] = useState(-1)

  return (
    <TemplatePage1
      title={"Voz de la gente"}
      hero={true}
      bg={"var(--tema4)"}
      tc={"white"}
      adorno={<Adorno />}
      content={(
        <Wrapper >
          <CardContainer>
            <Card>
              <TextZone>
                <h1 className={"text-2xl font-bold"} onClick={() => { select === 1 ? setselect(-1) : setselect(1) }} >Voz de la gente</h1>

                <div className={ "text-2xl text-justify show transition-all"}>
                  <p className={"text-xl"}>La Voz de la Gente es una encuesta sobre la calidad de los sistemas de salud diseñada por la Red QuEST Global (Evidencia de Calidad para la Transformación de los Sistemas de Salud - Quality Evidence for Health System Transformation), un consorcio académico de investigación multinacional centrado en la medición y la mejora de la calidad de los sistemas sanitarios.  Esta encuesta incluye mediciones de resultados de salud, expectativas de calidad, preferencias de utilización, calidad de la atención y confianza en el sistema de salud, entre otros, por parte de usuarios y no usuarios de los sistemas de salud. En Latinoamérica, esta encuesta se ha implementado en tres países (Colombia, Perú y Uruguay) a través de la Red QuEST Latinoamérica y el Caribe (QuEST LAC). 
                  </p>
                </div>

              </TextZone>
            </Card>
            <Card>
              <TextZone>
                <div className={"text-2xl text-justify show transition-all"} >
                  <p className={"text-xl"}>En 2022 se llevó a cabo la primera ola de recolección de datos de “La Voz de la Gente”. Los informes de esta ronda en Perú, Colombia y Uruguay, junto con otros once países de distintas regiones, están disponibles en la página web de la Red QuEST Global (<Link to={"https://www.questnetwork.org/peoples-voice-survey"} className={"text-red-800"}>enlace</Link>) . Además, se publicó en la revista The Lancet una serie de artículos que comparan los hallazgos regionales con los de otros países, brindando una visión más amplia del estado de la calidad de los sistemas de salud (<Link to={"https://www.thelancet.com/series/peoples-voice-survey"} className={"text-red-800"}>enlace</Link>). Por último, estos resultados se compartieron con autoridades gubernamentales para promover el diálogo y establecer mesas de trabajo enfocadas en rediseñar estrategias que mejoren la calidad de la atención en la región.
                  </p>
                </div>

              </TextZone>
            </Card>
            <Card>
              <TextZone>
                <h1 className={"text-2xl font-bold"} onClick={() => { select === 1 ? setselect(-1) : setselect(1) }} >REPORTES DE LA PRIMERA OLA DE “LA VOZ DE LA GENTE”</h1>

                <div className={ "grid grid-cols-1 lg:grid-cols-3 items-center  text-2xl  show transition-all"}>
                  
                    <Link to={"https://drive.google.com/file/d/1QCr74fpKUsp9p8FgeBvj0Hq2SW1DP1O6/view?usp=drive_link"} className={"text-red-800 px-2 py-1 text-center text-lg border mx-1 rounded-xl border-solid border-black"}>Reporte - Perú</Link>
                    
                    <Link to={"https://drive.google.com/file/d/1SKtmn5a50HfCmq7irIQLSUSAkLDCLIL4/view?usp=drive_link"} className={"text-red-800 px-2 py-1 text-center text-lg border mx-1 rounded-xl border-solid border-black"}>Reporte - Colombia</Link>
                    
                    <Link to={"https://drive.google.com/file/d/1wWe1VybK97L7Tri7dHkfgJZrBBlBeLFa/view?usp=drive_link"} className={"text-red-800 px-2 py-1 text-center text-lg border lg:mx-1 my-1 rounded-xl border-solid border-black"}>Reporte - Uruguay</Link>
                    
                </div>
                <div className={"text-2xl text-justify show transition-all mt-1"} >
                  <p className={"text-xl"}>Una segunda ola de la Encuesta La Voz de la gente se llevó a cabo entre octubre y noviembre de 2024.  Los resultados serán públicados y compartidos por este medio pronto
                  </p>
                </div>
              </TextZone>
            </Card>
          </CardContainer>
        </Wrapper >
      )}
    />
  )
};

export default IndexPage



const Hexagon = styled.div`
position: relative;
clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
background-color: ${props => props.bgc ? props.bgc : ""};
background-size: cover;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: center;

@media only screen and (min-width:768px){
  height: 150px;
max-height:150px;
min-height:150px;
width: 150px;
max-width:150px;
min-width:150px;

}
height: 90px;
max-height:90px;
min-height:90px;
width: 90px;
max-width:90px;
min-width:90px;
img{
  height:70%;
}
`

const Wrapper = styled.div`
padding-top: 1rem;
`;

const CardContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
`

const Card = styled.div`
border: solid 1px var(--tema4);
background-color:white;
border-radius: 20px;
display: flex;
flex-direction: ${props => props.left ? "row-reverse" : "row"};
justify-content: flex-start;
align-items: center;
padding: 2rem 1rem 2rem 1rem;
margin: 1rem 0 1rem 0;
width: 100%;
`;

const TextZone = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding:15px;
color: var(--tema4);
transition:all 1s;
will-change: contents;


h1{
  margin: 0.2rem 0 0.5rem 0;
  text-align: center;
  cursor: pointer;
}

.ownsmall h1{
  font-size:1rem;
}
.ownbig h1{
  font-size:2rem;
}


div{
  .ownshow{
    display: block;
  }
  .ownhidden{
    display: none;
  }
}
`;

const Adorno = styled.div`
position: absolute;
bottom: 0px;
right: 1100px;
height:100%;
@media only screen and (min-width:768px){
  bottom: 0px;
  left: -450px;
height:100%;
  width: 800px;
  background-position-x:0%;
  background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
@media only screen and (min-width:1200px){
  bottom: 0px;
left: -450px;
height:100%;
  width: 800px;
  background-position-x:0%;
  background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
`;